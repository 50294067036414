import { Col, Input, Row, Select, Table, Button, notification } from 'antd';
import { useState, useMemo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import type { TableColumnsType } from 'antd';
import { sortBy } from 'lodash';
import { DynamicKeyObject, IGetGgSheetParams } from 'models';
import {
  actionGetDriverList,
  actionGetDriverStructure,
  selectStateDriverList,
  selectStateDriverStructure,
} from 'store/sheetDriver';
import { actionPostSettingMapping, selectStatePostSettingMapping } from 'store/settingMapping';
import { actionGetGgSheet, selectStateGoogleSheet, actionResetDataGgSheet } from 'store/ggSheet';

interface IDataMapping {
  name: string;
  property: string;
  key: string;
}

// const link = 'https://docs.google.com/spreadsheets/d/1VYi54FxJ0pqzHq_N02E2tgyN4I7OG3JcFsaOGQ9xlUs/edit#gid=1218220957';
const initParamsGoogleSheet = {
  link: '',
};

function MappingSheet() {
  const dispatch = useAppDispatch();
  const stateDriverList = useAppSelector(selectStateDriverList);
  const stateDriverStructure = useAppSelector(selectStateDriverStructure);
  const stateGoogleSheet = useAppSelector(selectStateGoogleSheet);
  const statePostSettingMapping = useAppSelector(selectStatePostSettingMapping);

  const [currentDriverId, setCurrentDriverId] = useState<number | null | undefined>();
  const [dataMapping, setDataMapping] = useState<IDataMapping[]>([]);
  const [currentDataBaseTable, setCurrentDataBaseTable] = useState<DynamicKeyObject | null>();
  const [paramsGoogleSheet, setParamsGoogleSheet] = useState<IGetGgSheetParams>(initParamsGoogleSheet);

  const getOptionDriver = (data: DynamicKeyObject) => {
    const options = data.map((dataItem: DynamicKeyObject) => ({
      label: dataItem.db_name,
      value: dataItem.id,
    }));
    return options;
  };

  const getOptionTableInDriver = (data: DynamicKeyObject) => {
    const options = data.map(({ table }: DynamicKeyObject) => ({
      label: table,
      value: table,
    }));
    return options;
  };

  const getOptionTableCols = (data: []) => {
    const options = data.map((dataItem: string) => ({
      label: dataItem,
      value: dataItem,
    }));
    return options;
  };

  const handleLoadDataGoogleSheet = (params: IGetGgSheetParams) => {
    dispatch(actionGetGgSheet(params));
  };

  const handleSelectDriver = (value: number | null | undefined) => {
    setCurrentDriverId(value);
  };

  const handleSelectTableName = (value: DynamicKeyObject) => {
    setCurrentDataBaseTable(stateDriverStructure.data.find((dataItem: DynamicKeyObject) => dataItem.table === value));
    setDataMapping((prev) => prev.map((dataItem) => ({ ...dataItem, property: '' })));
  };

  const handleSaveMappingData = (params: DynamicKeyObject) => {
    dispatch(actionPostSettingMapping(params));
  };

  const handleResetInput = () => {
    setParamsGoogleSheet(initParamsGoogleSheet);
    setCurrentDriverId(null);
    setCurrentDataBaseTable(null);
    dispatch(actionResetDataGgSheet());
  };
  const paramsPostSheet = useMemo(() => {
    const mapping_values: DynamicKeyObject = {};
    for (const dataMappingItem of dataMapping) {
      const { name, property } = dataMappingItem;
      mapping_values[name] = property;
    }
    const params = {
      link: paramsGoogleSheet.link,
      driver_id: currentDriverId,
      table_name: currentDataBaseTable?.table,
      status: 0,
      mapping_values,
    };
    return params;
  }, [paramsGoogleSheet, currentDriverId, currentDataBaseTable?.table, dataMapping]);

  const colsMapping: TableColumnsType<IDataMapping> = useMemo(() => {
    const options = getOptionTableCols(currentDataBaseTable?.cols ?? []);
    const updateDataMapping = (key: string, value: any) => {
      const currentMappingProperty = dataMapping.find((dataMappingItem: IDataMapping) => dataMappingItem.key === key);
      const newDataMapping = dataMapping.filter((dataMappingItem: IDataMapping) => dataMappingItem.key !== key);
      if (currentMappingProperty) {
        currentMappingProperty.property = value;
        setDataMapping(sortBy([...newDataMapping, currentMappingProperty], ['key']));
      }
    };
    const currentColumns: TableColumnsType<IDataMapping> = [
      {
        title: 'Column Name',
        dataIndex: 'name',
        key: 'name',
        width: '50%',
      },
      {
        title: 'Mapping Setting',
        dataIndex: 'property',
        key: 'property',
        width: '50%',
        render: (_, record: IDataMapping) => (
          <Select
            className="w-full"
            showSearch
            placeholder="Select a table"
            optionFilterProp="children"
            value={record.property}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            onSelect={(value) => updateDataMapping(record.key, value)}
            options={options}
          />
        ),
      },
    ];
    return currentColumns;
  }, [currentDataBaseTable?.cols, dataMapping]);

  useEffect(() => {
    dispatch(actionGetDriverList());
    dispatch(actionResetDataGgSheet());
  }, [dispatch]);

  useEffect(() => {
    if (currentDriverId) {
      dispatch(
        actionGetDriverStructure({
          id: currentDriverId?.toString(),
        })
      );
    }
  }, [currentDriverId, dispatch]);

  useEffect(() => {
    const currentDataMapping = stateGoogleSheet.data.map((dataItem: string, index: number) => ({
      key: index,
      name: dataItem,
      property: '',
    }));
    setDataMapping(currentDataMapping);
  }, [stateGoogleSheet]);

  useEffect(() => {
    if (statePostSettingMapping.status === 'SUCCESSFULLY') {
      notification.success({
        message: 'Success',
        description: 'Data mapping successfully',
      });
    }
    if (statePostSettingMapping.status === 'ERROR') {
      notification.error({
        message: 'Error',
        description: 'Data mapping error',
      });
    }
  }, [dispatch, statePostSettingMapping]);
  return (
    <Row gutter={40} className="px-8 pt-4 ">
      <Col span={10} className="">
        <Row>
          <Col span={24}>
            <div className="flex gap-4 ">
              <div className="flex-[0_0_50%]">
                <h1 className="text-sm">Driver</h1>
                <Select
                  showSearch
                  placeholder="Select a driver"
                  onSelect={handleSelectDriver}
                  className="w-full"
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={getOptionDriver(stateDriverList.data)}
                  value={currentDriverId}
                />
              </div>
              <div className="flex-[50%]">
                <h1 className="text-sm">Table name</h1>
                <Select
                  disabled={!currentDriverId || stateDriverStructure.status === 'NONE'}
                  showSearch
                  placeholder="Select a table"
                  optionFilterProp="children"
                  className="w-full"
                  onSelect={handleSelectTableName}
                  value={
                    currentDataBaseTable
                      ? {
                          label: currentDataBaseTable?.table,
                          value: currentDataBaseTable?.rows,
                        }
                      : null
                  }
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? '')?.toLowerCase().includes(input.toLowerCase())
                  }
                  options={getOptionTableInDriver(stateDriverStructure.data)}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={24}>
            <div className="flex gap-4">
              <div className="flex-1">
                <h1 className="text-sm">Google sheet link</h1>
                <Input
                  onChange={(e) =>
                    setParamsGoogleSheet((prev) => ({
                      ...prev,
                      link: e.target.value,
                    }))
                  }
                  disabled={!currentDataBaseTable}
                  value={paramsGoogleSheet.link}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-[20px]" gutter={12}>
          <Col span={24}>
            <div className="flex gap-4 ">
              <Button
                className="w-28"
                type="primary"
                onClick={() => handleLoadDataGoogleSheet(paramsGoogleSheet)}
                disabled={!currentDriverId || !paramsGoogleSheet.link.trim() || !currentDataBaseTable}
              >
                Load
              </Button>
              <Button
                type="primary"
                className="w-28"
                onClick={() => handleResetInput()}
                // disabled={!!dataMapping.find((dataItem) => dataItem.property.trim() === '') || !dataMapping.length}
              >
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row className="mt-[24px]" gutter={16}>
          <Col span={24}>
            <Table
              dataSource={dataMapping}
              columns={colsMapping}
              pagination={false}
              className="shadow"
              rowKey="name"
              loading={stateGoogleSheet.loading}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="flex  flex-row-reverse mt-2">
              <Button
                type="primary"
                className="w-28 capitalize "
                onClick={() => handleSaveMappingData(paramsPostSheet)}
                disabled={!!dataMapping.find((dataItem) => dataItem.property.trim() === '') || !dataMapping.length}
              >
                save
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default MappingSheet;
