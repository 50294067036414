import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BaseState, DynamicKeyObject, IGetDriverListParams, IGetDriverStructureParams } from 'models';
import { RootState } from 'store';
import request from 'util/request';

const stateDriverList: BaseState = {
  loading: false,
  status: 'NONE',
  data: [],
};

const stateDriverStructure: BaseState = {
  loading: false,
  status: 'NONE',
  data: [],
};

const initialState: DynamicKeyObject = {
  stateDriverList,
  stateDriverStructure,
};

export const actionGetDriverList: any = createAsyncThunk(
  'driver/list-driver',
  async (params: IGetDriverListParams, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/driver/list-driver',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionGetDriverStructure: any = createAsyncThunk(
  'driver/driver-structure',
  async (params: IGetDriverStructureParams, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/driver/driver-structure',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'sheet-driver',
  initialState,
  reducers: {
    actionResetDriverListAndStructure: (state) => {
      state.stateDriverList.status = 'NONE';
      state.stateDriverList.data = [];
      state.stateDriverStructure.status = 'NONE';
      state.stateDriverStructure.data = [];
    },
  },

  extraReducers: {
    [actionGetDriverList.pending]: (state) => {
      state.stateDriverList.loading = true;
      state.stateDriverList.status = 'NONE';
    },
    [actionGetDriverList.rejected]: (state) => {
      state.stateDriverList.loading = false;
      state.stateDriverList.status = 'ERROR';
    },
    [actionGetDriverList.fulfilled]: (state, action) => {
      state.stateDriverList.loading = false;
      state.stateDriverList.status = 'SUCCESSFULLY';
      state.stateDriverList.data = action.payload.data;
    },
    [actionGetDriverStructure.pending]: (state) => {
      state.stateDriverStructure.loading = true;
      state.stateDriverStructure.status = 'NONE';
    },
    [actionGetDriverStructure.rejected]: (state) => {
      state.stateDriverStructure.loading = false;
      state.stateDriverStructure.status = 'ERROR';
    },
    [actionGetDriverStructure.fulfilled]: (state, action) => {
      state.stateDriverStructure.loading = false;
      state.stateDriverStructure.status = 'SUCCESSFULLY';
      state.stateDriverStructure.data = action.payload.data;
    },
  },
});

export const { actionResetDriverListAndStructure } = slice.actions;

export const selectStateDriverList = (state: RootState) => state.driverSheet.stateDriverList;
export const selectStateDriverStructure = (state: RootState) => state.driverSheet.stateDriverStructure;

export default slice.reducer;
