import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { keys } from 'lodash';
import { BaseState, IGetGgSheetParams } from 'models';
import { RootState } from 'store';
import request from 'util/request';

const initialState: BaseState = {
  loading: false,
  status: 'NONE',
  data: [],
};

export const actionGetGgSheet: any = createAsyncThunk(
  'gg-sheet',
  async (params: IGetGgSheetParams, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/gg-sheet',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'sheet-driver',
  initialState,
  reducers: {
    actionResetDataGgSheet: (state) => {
      state.data = [];
      state.loading = false;
      state.status = 'NONE';
    },
  },

  extraReducers: {
    [actionGetGgSheet.pending]: (state) => {
      state.loading = true;
      state.status = 'NONE';
    },
    [actionGetGgSheet.rejected]: (state) => {
      state.loading = false;
      state.status = 'ERROR';
    },
    [actionGetGgSheet.fulfilled]: (state, action) => {
      state.loading = false;
      state.status = 'SUCCESSFULLY';
      state.data = keys(action.payload.data[0]);
    },
  },
});

export const { actionResetDataGgSheet } = slice.actions;

export const selectStateGoogleSheet = (state: RootState) => state.googleSheet;

export default slice.reducer;
