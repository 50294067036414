import { TableOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import URL from 'constants/url';
import { IDefaultLayoutProps, IMenuItem } from 'models';
import moment from 'moment';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

const { Content, Footer, Sider, Header } = Layout;
function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: IMenuItem[]): IMenuItem {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items: IMenuItem[] = [getItem('DataSheetMapping', URL.Home, <TableOutlined />)];

export default function DefaultLayout(props: IDefaultLayoutProps) {
  const { children } = props;
  const currentYear = moment().format('YYYY');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  function handleSelectItem(data: { key: string }) {
    navigate(data.key);
  }

  return (
    <Layout hasSider className="min-h-screen">
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} width="180">
        <div className="w-full h-[56px] flex items-center justify-center p-2">
          <img src={logo} alt="logo" className="w-full" />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          items={items}
          defaultSelectedKeys={[pathname]}
          onSelect={handleSelectItem}
          className="p-0"
        />
      </Sider>
      <Layout hasSider={false}>
        <Header className="h-[60px]" />
        <Content className="bg-white px-2 pb-2">{children}</Content>
        <Footer className="text-center h-[48px]">
          <div className="flex items-center justify-center h-full">
            Copyright {currentYear} TIDESQUARE CORPORATION. All rights reserved.
          </div>
        </Footer>
      </Layout>
    </Layout>
  );
}
