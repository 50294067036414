import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BaseState } from 'models';
import { RootState } from 'store';
import request from 'util/request';

const initialState: BaseState = {
  loading: false,
  status: 'NONE',
};

export const actionPostSettingMapping: any = createAsyncThunk(
  'setting-mapping/setting-mapping',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/setting-mapping/setting-mapping',
        method: 'POST',
        data: params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'sheet-driver',
  initialState,
  reducers: {},

  extraReducers: {
    [actionPostSettingMapping.pending]: (state) => {
      state.loading = true;
      state.status = 'NONE';
    },
    [actionPostSettingMapping.rejected]: (state) => {
      state.loading = false;
      state.status = 'ERROR';
    },
    [actionPostSettingMapping.fulfilled]: (state) => {
      state.loading = true;
      state.status = 'SUCCESSFULLY';
    },
  },
});

// export const {} = slice.actions;

export const selectStatePostSettingMapping = (state: RootState) => state.settingMapping;

export default slice.reducer;
