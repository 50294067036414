import { Tabs } from 'antd';
import Driver from 'app/components/home/Driver';
import MappingSheet from 'app/components/home/MappingSheet';

const tabsItems = [
  // { label: 'driver', key: 'item-1', children: <Driver /> },
  { label: 'Mapping', key: 'item-2', children: <MappingSheet /> },
];

function Home() {
  return (
    <div className="px-4">
      <Tabs items={tabsItems} />
    </div>
  );
}
export default Home;
